export const environment = {
  isReleaseBuild: false,
  envName: 'Project Two',
  isDev: true,
  enableDebug: false,
  useCache: false,
  useProxy: true,
  baseApiUrl: '/Services/MessengerProjTwo/',
  host: 'https://webservicesmo.energytransfer.com',
  port: ':443',
  secondaryUrl: 'https://webservicesmo.energytransfer.com',
  infoPostBaseUrl: 'https://projecttwo.peplmessenger.energytransfer.com/project2/ipost',
  extSecurityUrl: 'https://mo.security.energytransfer.net/'
};
